import React from 'react';
import styled from "styled-components"

// import GStyledComponents from "GStyledComponents"
const NextButton = (props) => {  

  const button2 = (path) => {
    window.location.href = path
  }
    const [email, setEmail] = React.useState("")
  const [emailError, setEmailError] = React.useState("")
  const [emailSent, setEmailSent] = React.useState("")

  const handleSumit = event => {
    console.log("FUCK YOU")
    // use to make sure page isn't reloaded when we send off content
    event.preventDefault()
    // don't remember from where i copied this code, but this works.
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    console.log("FUCK YOU")

    if (re.test(email)) {
      // this is a valid email address
      // call setState({email: email}) to update the email
      // or update the data in redux store.

      var axios = require("axios")
      var data = JSON.stringify({
        email: email,
        dataFields: { source: "CTA_Button_right" },
      })

      // frontend iterable
      var config = {
        method: "post",
        url: "https://api.iterable.com/api/users/update",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": "dd373517425447ca8ab5d993282d17ab",
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setEmailSent("We'll send you info!")
        })
        .catch(function (error) {
          console.log(error)
        })

      console.log(email)
      setEmailError("")
    } else {
      // invalid email, maybe show an error to the user.
      console.log(email)
      setEmailSent("")
      setEmailError("Please set a valid email")
    }
  }

  return (
    <Hero>
    {/*<Hero2>*/}
        <HowItWorks>{props.title}</HowItWorks>
        <Button onClick={()=> button2(props.link1)}>{props.btitle}</Button>
        <CTADiv>
          <CTAForm
            style={{
              flexDirection: "row",
              // display: "grid",
              // jusityitems: "center",
              // alignitems: "center",
            }}
            onSubmit={handleSumit}
          >
            <CTAInput
              type="text"
              placeholder="email"
              error={emailError}
              onChange={event => setEmail(event.target.value)}
            ></CTAInput>

            <CTAButton type="submit">Go</CTAButton>
          </CTAForm>

        </CTADiv>
          <Span1 style={{ color: "red" }}>{emailError}</Span1>
          <Span1 style={{ color: "black" }}>{emailSent}</Span1>
        <Button2 onClick={()=> button2(props.link2)}>{props.btitle1}</Button2>
        {/*<SubText>{props.detail}</SubText>*/}
    {/*<Hero2>*/}
    </Hero>
    );
}

export default NextButton

const Hero5 = styled.div`
  // margin:  15px;
  // align-text:  center;
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  `

const Button = styled.button`
  // position:absolute;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;
  color: #ffffff;
  background: #c73967;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 745px) {
      // right:140px;
      // top:140px;
      // width:200px;
      width:80%;
      visibility:hidden;
  }

  // @media only screen and (max-width: 1399px) and (min-width: 426px) {
  //   width:80%;
  // }

  @media only screen and (max-width: 744px) and (min-width: 100px) {
    left:auto;
    right:auto;
    z-index:10;
    width:90%;
    justify-content:center;
  }
`
const Button2 = styled.button`
  // position:absolute;
  cursor: pointer;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16.1461px;
  line-height: 24px;
  color: #ffffff;
  background: #8bbef3;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 745px) {
      // right:140px;
      // top:140px;
      // width:200px;
    width:80%;
    margin-bottom:135px;
  }

  @media only screen and (max-width: 744px) and (min-width: 426px) {
    width:80%;
    // margin-bottom:80px;
    //     left:auto;
    // right:auto;
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    left:auto;
    right:auto;
    z-index:10;
    width:90%;
    justify-content:center;
    // margin:

  }
`

// const SubText = styled.h2`
//   position: relative;
//   left: auto;
//   right: auto;
//   font-family: Poppins;
//   // font-style: normal;
//   // font-weight: 300;
//   font-size: 12.9714px;
//   // line-height: 5px;
//   // letter-spacing: 1.26101px;
//   // text-transform: uppercase;

//   color: grey;
//   @media only screen and (max-width: 2000px) and (min-width: 1400px) {
//   }

//   @media only screen and (max-width: 1399px) and (min-width: 426px) {
//   }

//   @media only screen and (max-width: 425px) and (min-width: 100px) {
//     // left: 60.12px;
//     // top: 0.63px;
//     // padding-top:140px;
//   }
// `

const HowItWorks = styled.h2`
  position: relative;

  font-family: Poppins;
  font-style: normal;
  // font-weight: 600;
  font-size: 25.7269px;
  // line-height: 0px;
  /* identical to box height */
  letter-spacing: 1.56101px;
  text-transform: uppercase;

  color: black;

  // opacity: 0.04;

  @media only screen and (max-width: 2000px) and (min-width: 745px) {
    visibility:hidden;

    // &:hover {  
    //   color: black;
      
    // }
    // margin-top:70px;
  }
  @media only screen and (max-width: 744px) and (min-width: 426px) {
    // margin-top:70px;   
    // font-size: 35.7269px;
    // line-height: 50px;
  }
  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`
// const HowItWorks2 = styled.h2`
//   position: relative;
//   // width: 358px;
//   // height: 60px;
//   left: auto;
//   right: auto;
//   // top: 50.63px;

//   font-family: Poppins;
//   font-style: normal;
//   // font-weight: 600;
//   font-size: 24.9714px;
//   // line-height: 45px;
//   letter-spacing: 1.56101px;
//   text-transform: uppercase;

//   color: black;
//   @media only screen and (max-width: 2000px) and (min-width: 1400px) {
//   }
//   @media only screen and (max-width: 1399px) and (min-width: 426px) {
//     // font-size: 17.9714px;
//     // line-height: 25px;
//   }
//   @media only screen and (max-width: 425px) and (min-width: 100px) {
//     // left: 60.12px;
//     // top: 0.63px;
//     // padding-top:140px;
//   }
// `
const StandardText = styled.h2`
  position: relative;
  margin-top: 20px;
  bottom: auto;
  left: auto;
  right: auto;
  font-family: Poppins;
  font-style: normal;
  // font-weight: 300;
  font-size: 12.9714px;
  // line-height: 5px;
  letter-spacing: 1.26101px;
  // text-transform: uppercase;

  color: #000000;
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }
  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
    // left: 60.12px;
    // top: 0.63px;
    // padding-top:140px;
  }
`


const CTADiv = styled.div`
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content:stretch;
  align-items: stretch;
`


const CTAButton = styled.button`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // padding: 16px;
  cursor: pointer;
  // padding-left: 10px;
  font-family: Poppins;
  // font-style: normal;
  // font-weight: bold;
  // font-size: 16.1461px;
  // line-height: 24px;
  // left:auto;
  color: #ffffff;

  background: #8578fc;
  box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
  border-radius: 40.3653px;

  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);
  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const CTAForm = styled.form`
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(230px, 2fr));
`

const CTAInput = styled.input`
  // display: flex;
  // padding: 16.1461px 30.7772px;

  font-family: Poppins;
  font-style: normal;
  // font-weight: bold;
  // font-size: 11.1461px;  
  // line-height: 24px;
  // margin-left:-0px;
  // margin-right:100px;

  color: black;

  border: 0.897006px solid #8578fc;
  box-sizing: border-box;
  filter: drop-shadow(0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26));
  border-radius: 40.3653px;
  animation: SubContentAnimations;
  animation-delay: 0.1s;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.2, 0.8, 0.2, 1);

  @media only screen and (max-width: 2000px) and (min-width: 1400px) {
  }

  @media only screen and (max-width: 1399px) and (min-width: 426px) {
  }

  @media only screen and (max-width: 425px) and (min-width: 100px) {
  }
`

const Span1 = styled.span`
 // position:absolute;
 
 
 @media only screen and (max-width: 2000px) and (min-width: 745px) {
  visibility:hidden;
  margin:-10px;
  display:none;
  }
`

const Hero = styled.div`
   position: fixed;
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;  
   alignItems: stretch;
   color:white;
   border-radius:33px;
   align-items: center;
   border-color: rgba(102, 75, 218, 0.26);
   box-shadow: 0px 3.58802px 22.4251px rgba(102, 75, 218, 0.26);
   border-radius: 40.3653px;
   z-index: 10;

  @media only screen and (max-width: 2000px) and (min-width: 745px) {
    transition: width 0.5s, height 0.5s;
    &:hover { 
      bottom: 50px;
      right:50px;
      width: 300px;
      height:300px;
      background: white;
      line-height: 2;
      text-align: center;
      border-radius: 0.0px;
      flex-direction: column;
      border-radius:33px;
      transition: width 0.5s, height 0.5s;

    }
    ${CTADiv} {
      visibility:hidden;
    }

    &:hover ${HowItWorks} {
      visibility:visible;
    }
    &:hover ${Button} {
      visibility:visible;
    }
    &:hover ${Button2} {
      margin-bottom:0px;
    }
    &:hover ${HowItWorks} {
      visibility:visible;
    }
    &:hover ${CTADiv} {
      visibility:visible;
    }
    &:hover ${Span1} {
      visibility:visible;
      display:block;
    }
    // &:hover ${HowItWorks} {
    //   visibility:visible;
    // }
        
    bottom: 50px;
    right:50px;
    width: 300px;
    height:60px;
    background: white;
    line-height: 2;
    text-align: center;
    border-radius: 0.0px;
    flex-direction: column;
    border-radius:33px;

  }
  // @media only screen and (max-width: 744px) and (min-width: 100px) {
    
  //   bottom: 0;
  //   width: 100%;
  //   height:100px;
  //   background: white;
  //   line-height: 2;
  //   text-align: center;
  //   border-radius: 0.0px;
  //   flex-direction: row;

  //   ${CTADiv} {
  //     display:none;
  //   }
    
  // }
  @media only screen and (max-width: 744px) and (min-width: 100px) {
    bottom: 0;
    width: 100%;
    height:140px;
    border-radius: 0.0px;
    background: white;
    line-height: 2;
    text-align: center;

    ${CTADiv} {
      display:none;
    }

    // color: #042E64;
    // font-size: 30px;
    // font-family: sans-serif;
    // font-weight: bold;
    // text-shadow: 0 1px 0 #84BAFF;
    // box-shadow: 0 0 15px #00214B
  }



`